import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Box, IconButton } from '@mui/material';
import React from 'react';

import {
  EMAIL_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  YOUTUBE_CHANNEL_URL,
} from '../../../constants/external-links.constants';

const SocialMediaLinks = ({ isFooter }) => {
  return (
    <Box>
      <IconButton href={LINKEDIN_URL} sx={{ color: 'main_dark_blue' }} target="_blank">
        <LinkedInIcon sx={{ width: '30px', height: '30px' }} />
      </IconButton>
      <IconButton href={FACEBOOK_URL} sx={{ color: 'main_dark_blue' }} target="_blank">
        <FacebookIcon sx={{ width: '30px', height: '30px' }} />
      </IconButton>
      <IconButton href={EMAIL_URL} sx={{ color: 'main_dark_blue' }}>
        <AlternateEmailRoundedIcon sx={{ width: '30px', height: '30px' }} />
      </IconButton>
      {isFooter && (
        <IconButton href={YOUTUBE_CHANNEL_URL} sx={{ color: 'main_dark_blue' }} target="_blank">
          <YouTubeIcon sx={{ width: '30px', height: '30px' }} />
        </IconButton>
      )}
    </Box>
  );
};

export default SocialMediaLinks;
