import React, { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import NavigateHome from './components/Layout/components/NavigateHome';
import { RoutesUrls } from './constants/routes-urls.constants';
import PageLayoutWrapper from './pages/PageLayoutWrapper';

const HomePage = lazy(() => import('./pages/Home'));
const FeedBackPage = lazy(() => import('./pages/FeedBackPage'));

//todo loader in fallback prop
export const router = createBrowserRouter([
  {
    element: <PageLayoutWrapper />,
    children: [
      {
        path: RoutesUrls.Home,
        element: (
          <Suspense fallback={<></>}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: RoutesUrls.FeedBackForm,
        element: (
          <Suspense fallback={<></>}>
            <FeedBackPage />
          </Suspense>
        ),
      },
      { path: '*', element: <NavigateHome /> },
    ],
  },
]);
