import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import SocialMediaLinks from './SocialMediaLinks';
import { MENU_OPTIONS } from '../../../constants/layout.constants';
import { RoutesUrls } from '../../../constants/routes-urls.constants';
import { LOCALE_OPTIONS } from '../../../constants/translations/translations';
import { useTranslation } from '../../../custom-hooks/useTranslation';
import { scrollToElementById } from '../../../utils/layout.utils';
import { LocaleContext } from '../../../utils/translations.utils';

const MobileMenu = () => {
  const translateString = useTranslation();
  const { locale, setLocale } = useContext(LocaleContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavMenuClick = (sectionId) => {
    scrollToElementById(sectionId);
    handleClose();
  };

  const handleLocaleChange = (value) => {
    if (value !== locale) {
      setLocale(value);
      handleClose();
    }
  };

  const isLocaleSelected = (value) => value === locale;

  return (
    <>
      <IconButton
        sx={{ display: { lg: 'none' } }}
        aria-label="mobile-menu"
        onClick={handleOpenMenu}
      >
        <MenuRoundedIcon sx={{ color: 'main_dark_blue', height: '35px', width: '35px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: '260px',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {MENU_OPTIONS.map(({ label, sectionId }) => (
          <MenuItem
            sx={{ justifyContent: 'center' }}
            key={sectionId}
            onClick={() => handleNavMenuClick(sectionId)}
          >
            <Link
              to={`${RoutesUrls.Home}#${sectionId}`}
              scroll={() => handleNavMenuClick(sectionId)}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant="h6">{translateString(label)}</Typography>
            </Link>
          </MenuItem>
        ))}
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }}>
          <Typography variant="h6" sx={{ marginRight: '5px' }}>
            {translateString('Language:')}
          </Typography>
          {LOCALE_OPTIONS.map(({ value, label }) => (
            <Button
              key={value}
              variant={isLocaleSelected(value) ? 'container' : ''}
              sx={{
                marginRight: '5px',
                color: isLocaleSelected(value) ? 'white' : 'main_dark_blue',
                bgcolor: isLocaleSelected(value) ? 'main_purple' : '',
                ':hover': { bgcolor: isLocaleSelected(value) ? 'main_light_purple' : '' },
              }}
              onClick={() => handleLocaleChange(value)}
            >
              {label}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
          <SocialMediaLinks />
        </Box>
      </Menu>
    </>
  );
};
export default MobileMenu;
