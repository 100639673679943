import { createTheme } from '@mui/material/styles';

export const MAIN_THEME = createTheme({
  typography: {
    fontFamily: 'Nunito',
    body1: {
      color: '#273269',
      userSelect: 'none',
    },
    h1: {
      color: '#273269',
      fontWeight: 700,
      userSelect: 'none',
    },
    h2: {
      color: '#273269',
      userSelect: 'none',
      fontWeight: 700,
    },
    h3: {
      color: '#273269',
      fontWeight: 700,
      userSelect: 'none',
    },
    h4: {
      color: '#273269',
      fontWeight: 800,
      userSelect: 'none',
    },
    h5: {
      color: '#273269',
      fontWeight: 700,
      userSelect: 'none',
    },
    h6: {
      color: '#273269',
      fontWeight: 700,
      userSelect: 'none',
    },
    button: {
      textTransform: 'none',
      fontSize: 18,
      fontWeight: 700,
    },
  },

  //todo write common styles for primary, secondary etc. buttons
  palette: {
    main_dark_blue: '#273269',
    main_light_blue: '#6899FF',
    main_purple: '#7784EE',
    main_dark_purple: '#6648FF',
    middle_purple: '#CA2CFF',
    main_light_purple: '#B389FF',
    main_dark_green: '#3CDA5F',
    main_light_green: '#B4FEC4',
    main_toxic_purple: '#6648FF',
    error_red: '#d32f2f',
  },
});
