import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import GlobalLoader from './components/GlobalLoader';
import { DEFAULT_LANGUAGE } from './constants/translations/translations';
import { router } from './Router';
import { MAIN_THEME } from './themes/mainTheme';
import { GlobalLoadingContext } from './utils/global-loader.utils';
import { LocaleContext } from './utils/translations.utils';

const App = () => {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || DEFAULT_LANGUAGE);
  const [isGlobalLoading, setGlobalLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return (
    <>
      <CssBaseline />
      <LocaleContext.Provider value={{ locale, setLocale }}>
        <ThemeProvider theme={MAIN_THEME}>
          <GlobalLoadingContext.Provider value={{ setGlobalLoading }}>
            <RouterProvider router={router} />
            {isGlobalLoading && <GlobalLoader />}
          </GlobalLoadingContext.Provider>
        </ThemeProvider>
      </LocaleContext.Provider>
    </>
  );
};

export default App;
