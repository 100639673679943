import { Box, Container } from '@mui/material';
import React from 'react';

import AppBarHeader from './components/AppBarHeader';
import { APP_BAR_HEIGHT } from '../../constants/layout.constants';

const Layout = ({ children }) => {
  return (
    <Container
      sx={{
        maxWidth: {
          xl: '90%',
          lg: '100%',
        },
        padding: {
          lg: '0px 24px',
          xs: '0px !important',
        },
      }}
    >
      <AppBarHeader />
      <Box sx={{ marginTop: `${APP_BAR_HEIGHT}px` }}>{children}</Box>
    </Container>
  );
};
export default Layout;
