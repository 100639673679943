import { AppBar, Box, Container, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import LocaleSwitcher from './LocaleSwitcher';
import MobileMenu from './MobileMenu';
import SocialMediaLinks from './SocialMediaLinks';
import fullLogo from '../../../assets/full_logo.png';
import {
  APP_BAR_HEIGHT,
  HOME_PAGE_SECTIONS_IDS,
  MENU_OPTIONS,
} from '../../../constants/layout.constants';
import { RoutesUrls } from '../../../constants/routes-urls.constants';
import { useTranslation } from '../../../custom-hooks/useTranslation';
import { scrollToElementById } from '../../../utils/layout.utils';

const AppBarHeader = () => {
  const translateString = useTranslation();
  const { pathname } = useLocation();

  const [selectedMenuItem, setSelectedMenuItem] = useState(HOME_PAGE_SECTIONS_IDS.MAIN_INFO);

  useEffect(() => {
    if (pathname !== RoutesUrls.Home && selectedMenuItem) {
      setSelectedMenuItem(null);
    }
  }, [pathname, selectedMenuItem]);

  const handleNavMenuClick = (sectionId) => {
    setSelectedMenuItem(sectionId);

    scrollToElementById(sectionId);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'white',
        backgroundImage: 'none',
        height: `${APP_BAR_HEIGHT}px`,
      }}
    >
      <Container
        sx={{
          maxWidth: {
            xl: '90%',
            lg: '100%',
          },
        }}
        maxWidth="false"
      >
        <Toolbar variant="regular">
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <img style={{ height: 60, marginRight: 30 }} alt="logo" src={fullLogo}></img>
            <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
              {MENU_OPTIONS.map(({ label, sectionId }, index) => (
                <MenuItem sx={{ py: '6px', px: '12px', borderRadius: '50px' }} key={index}>
                  <Link
                    to={`${RoutesUrls.Home}#${sectionId}`}
                    scroll={() => handleNavMenuClick(sectionId)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: selectedMenuItem === sectionId ? 'middle_purple' : 'main_dark_blue',
                      }}
                    >
                      {translateString(label)}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
              <LocaleSwitcher />
              <SocialMediaLinks />
            </Box>
            <MobileMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppBarHeader;
