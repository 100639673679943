import { useContext } from 'react';

import { LocaleContext } from '../utils/translations.utils';

export const useTranslation = () => {
  const { locale } = useContext(LocaleContext);
  const translations = require(`../constants/translations/${locale}.translations.json`);

  const translateString = (key) => translations[key] || key;

  return translateString;
};
