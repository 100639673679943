import { APP_BAR_HEIGHT } from '../constants/layout.constants';

export const scrollToElementById = (elementId) => {
  const sectionElement = document.getElementById(elementId);

  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - APP_BAR_HEIGHT;
    sectionElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth',
    });
  }
};

export const getCloseIconDecorationAmount = (width) =>
  width < 450 ? [7, 6] : width < 900 ? [8, 6] : [11, 9];
