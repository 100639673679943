import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

const GradientCircularProgress = () => {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#6899FF" />
            <stop offset="100%" stopColor="#B389FF" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </>
  );
};

const GlobalLoader = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  });

  return (
    <Box
      sx={{
        top: 0,
        display: 'flex',
        flexGrow: 1,
        backgroundColor: 'rgb(255 255 255 / 70%)',
        position: 'absolute',
        zIndex: 3000,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <GradientCircularProgress />
    </Box>
  );
};

export default GlobalLoader;
