import TranslateIcon from '@mui/icons-material/Translate';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';

import { LOCALE_OPTIONS } from '../../../constants/translations/translations';
import { LocaleContext } from '../../../utils/translations.utils';

const LocaleSwitcher = () => {
  const { locale, setLocale } = useContext(LocaleContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleLocaleSelect = (value) => {
    if (value !== locale) {
      onClose();
      setLocale(value);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <TranslateIcon sx={{ color: 'main_dark_blue', width: '30px', height: '30px' }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {LOCALE_OPTIONS.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            selected={value === locale}
            onClick={() => handleLocaleSelect(value)}
            sx={{ color: value === locale ? 'middle_purple' : 'main_dark_blue', fontWeight: 800 }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LocaleSwitcher;
