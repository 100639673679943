export const APP_BAR_HEIGHT = 65;

export const HOME_PAGE_SECTIONS_IDS = {
  FOOTER: 'footer',
  MAIN_INFO: 'main_info',
  PARTNERSHIP_INFO: 'partnership_info',
  PROJECT_INFO: 'project_info',
  TEAM_INFO: 'team_info',
};

export const MENU_OPTIONS = [
  { label: 'main_menu_itm', sectionId: HOME_PAGE_SECTIONS_IDS.MAIN_INFO },
  { label: 'team_info_menu_itm', sectionId: HOME_PAGE_SECTIONS_IDS.TEAM_INFO },
  { label: 'project_info_menu_itm', sectionId: HOME_PAGE_SECTIONS_IDS.PROJECT_INFO },
  { label: 'partnership_menu_itm', sectionId: HOME_PAGE_SECTIONS_IDS.PARTNERSHIP_INFO },
  { label: 'contacts_menu_itm', sectionId: HOME_PAGE_SECTIONS_IDS.FOOTER },
];
