import React from 'react';
import { Navigate } from 'react-router-dom';

import { RoutesUrls } from '../../../constants/routes-urls.constants';

const NavigateHome = () => {
  return <Navigate to={{ pathname: RoutesUrls.Home }} />;
};

export default NavigateHome;
